
<template>
<div class="row justify-content-center" style="height: 100vh;">
    <div class="col-md-8 col-lg-6 col-xl-5 d-flex align-items-center justify-content-center flex-column mx-auto">
        <div class="p-4">
            <div class="error-ghost text-center">
                <img src="/images/error.svg" width="200" alt="error-image" />
            </div>
            <div class="text-center">
                <h3 class="mt-4 text-uppercase font-weight-bold">Page not found </h3>
                <b-button @click="redirect" class="btn btn-primary mt-3"><i class="mdi mdi-reply mr-1"></i> Return Home</b-button>
            </div>
        </div>
    </div>
</div>
</template>

<script>

export default {
  methods: {
    redirect(){
      location.replace(window.location.origin+'/dashboard')
    },
   },

  }
</script>